let content = {
	"language": "RUS",
	"logo_subtitle": "Alt-Jugendstilhaus direkt am Kurfürstendamm",
	"menu":{
		"zimmer": "Комнаты",
		"lage": "Расположение",
		"service": "Сервис",
		"reservierung": "Бронирование",
		"kontakt": "Контакты"
	},
	"home":{
		"title": "Hotel Castell: добро пожаловать",
		"headline": "Добро пожаловать в <br> отель Castell",
		"text": "Гармоничный интерьер нашего дома в 'Alt-Jugend' стиле создаёт ощущение ностальгического шарма. Удобное расположение нашего отеля в самом центре города в нескольких шагах от торговой улицы 'Kurfürstendamm'. Удобное транспортное сообщение с аэропортом, вокзалом и выставочным центром. Для осмотра достопримечательностей города и посещения торговых центров Вы можете отправиться пешком."
	},
	"rooms":{
		"title": "Hotel Castell: Комнаты",
		"headline": "Комнаты",
		"text": "Мы предлагаем Вам 30 тихих уютных комнат. Наши номера c душем, феном, туалетом, телевизором и телефоном. Одноместные, двухместные, трехместные и четырехместные номера. При необходимости возможна установка в номере дополнительной кровати. По запросу может быть предоставлен номер с балконом."
	},
	"location":{
		"title": "Hotel Castell: Расположение",
		"headline": "Расположение",
		"anreise": "Как добраться",
		"auto": "Автомобилем",
		"auto_text": "по автобану A100 до выезда на 'Kurfürstendamm'",
		"bus": "Автобусы",
		"bus_text": "101, 109, 110, M19, M29, X10 до остановки 'Olivaer Platz'",
		"ubahn": "Метро (U-Bahn)",
		"ubahn_text": "U1 Uhlandstraße<br>U7 Adenauerplatz",
		"sbahn": "Электричка (S-Bahn)",
		"sbahn_text": "Savignyplatz - выход 'Schlüterstrasse'",
		"umgebung": "В непосредственной близости",
		"restaurants": "многочисленные рестораны",
		"sehenswuerdigkeiten": "множество достопримечательностей",
		"luxusgeschaefte": "магазины всемирноизвестных фирм",
		"chanel": "Chanel",
		"louisVuitton": "Louis Vuitton",
		"burburry": "Burburry",
		"rolex": "Rolex",
		"prada": "Prada",
		"longchamp": "Longchamp",
		"mcm": "MCM"
	},
	"service":{
		"title": "Hotel Castell: Сервис",
		"headline": "Сервис",
		"rezeption": "Рецепция",
		"parkplatz": "Парковка",
		"internet": "Интернет",
		"fruehstueck": "Завтрак",
		"rezeption_text": "Приём гостей производится 24 часа в сутки. Вас встретит приветливый персонал, говорящий по-русски.",
		"parkplatz_text": "У нас Вы можете приобрести парковочные талоны. Это даст Вам возможность бесплатно парковать машину в парковой зоне '6'.",
		"internet_text": "Во всех номерах есть бесплатный беспроводной доступ в Интернет.",
		"fruehstueck_text": "С 7:30 до 10:00 мы предлагаем нашим гостям обильный завтрак в форме 'шведский стол'."
	},
	"reservation":{
		"title": "Hotel Castell: Бронирование",
		"headline": "Запрос для бронирования",
		"vorname": "Имя",
		"name": "Фамилия",
		"anreisedatum": "Дата прибытия",
		"abreisedatum": "Дата выезда",
		"einzelzimmer": "Одноместный номер",
		"doppelzimmer": "Двухместный номер",
		"dreibettzimmer": "Трёхместный номер",
		"vierbettzimmer": "Четырёхместный номер",
		"fruehstueck": "С завтраком",
		"mail": "Электронная почта",
		"tel": "Номер телефона",
		"extras": "Особые пожелания",
		"privacypolicy": "I agree that the provided data are submitted to Hotel Castell to create an offer and the offer will be sent to me by mail. For any queries about the offer or urgent matters, the Hotel Castell Berlin can also contact me by phone. For more privacy related to the site, see <a href='/de/privacypolicy/'>Datenschutz</a>.",
        "anfrageSenden": "Отправить запрос"
	},
	"contact": {
		"title": "Hotel Castell: Контакты",
		"headline": "Контакты",
		"hotel": "Отель Castell",
		"inhaber": "Владелец",
		"tel": "Телефон",
        "oder": "или",
		"fax": "Факс",
		"web": "Сайт",
		"mail": "Электронная почта",
		"steuerNr": "НДС"
	},
    "thx":{
        "title": "Hotel Castell: спасибо",
        "headline": "Спасибо за Ваш запросß",
        "text": "Мы вышлем вам в ближайшее время предложение."
    }
}

export default function getContentRU() {
	return content;
};