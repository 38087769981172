import React from "react";
import { Link } from "react-router-dom";
import "./Rooms.css";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

class Rooms extends React.Component {

	render() {
		const images = [
			{
				original: '/images/pictures/Zimmer_01.jpg',
				thumbnail: '/images/pictures/Thumb_Zimmer_01.jpg'
			},{
				original: '/images/pictures/Zimmer_02.jpg',
				thumbnail: '/images/pictures/Thumb_Zimmer_02.jpg'
			},{
				original: '/images/pictures/Zimmer_03.jpg',
				thumbnail: '/images/pictures/Thumb_Zimmer_03.jpg'
			},{
				original: '/images/pictures/Zimmer_04.jpg',
				thumbnail: '/images/pictures/Thumb_Zimmer_04.jpg'
			},{
				original: '/images/pictures/Zimmer_05.jpg',
				thumbnail: '/images/pictures/Thumb_Zimmer_05.jpg'
			},{
				original: '/images/pictures/Zimmer_06.jpg',
				thumbnail: '/images/pictures/Thumb_Zimmer_06.jpg'
			},{
				original: '/images/pictures/Zimmer_07.jpg',
				thumbnail: '/images/pictures/Thumb_Zimmer_07.jpg'
			},{
				original: '/images/pictures/Zimmer_08.jpg',
				thumbnail: '/images/pictures/Thumb_Zimmer_08.jpg'
			},{
				original: '/images/pictures/Zimmer_09.jpg',
				thumbnail: '/images/pictures/Thumb_Zimmer_09.jpg'
			},{
				original: '/images/pictures/Bad_01.jpg',
				thumbnail: '/images/pictures/Thumb_Bad_01.jpg'
			},{
				original: '/images/pictures/Bad_02.jpg',
				thumbnail: '/images/pictures/Thumb_Bad_02.jpg'
			},{
				original: '/images/pictures/Bad_03.jpg',
				thumbnail: '/images/pictures/Thumb_Bad_03.jpg'
			},{
				original: '/images/pictures/Bad_04.jpg',
				thumbnail: '/images/pictures/Thumb_Bad_04.jpg'
			}
		];

		document.title = this.props.c("rooms.title");
		return (
			<div className="">
				<div className="container text-center">
					<h1>{this.props.c("rooms.headline")}</h1>
					<div className="fs-4">
						{this.props.c("rooms.headertext")}
					</div>
				</div>
				<div className="mt-4">
					<ImageGallery items={images} showPlayButton={false} />
				</div>
				<div className="fs-3 p-3 text-center">
					<h2>{this.props.c("rooms.zimmerausstattung")}</h2>
				</div>
				<div className="container-fluid mt-2 bg-secondary">
					<div className="row mb-2">
						<div className="col-sm">
							<div className="card-body text-center fs-5 text-white">
								<i className="fa-solid fa-ban-smoking"></i>
								<div>{this.props.c("rooms.nichtraucher")}</div>
							</div>
						</div>
						<div className="col-sm mt-2">
								<div className="card-body text-center fs-5 text-white">
									<i className="fa-solid fa-wifi"></i>
									<div>{this.props.c("rooms.wlan")}</div>
								</div>
						</div>
						<div className="col-sm mb-2">
							<div className="card-body text-center fs-5 text-white">
								<i className="fa-solid fa-shower"></i>
								<div>{this.props.c("rooms.dusche")}</div>
							</div>
						</div>
						<div className="col-sm mb-2">
							<div className="card-body text-center fs-5 text-white">
								<i className="fa-solid fa-pump-soap"></i>
								<div>{this.props.c("rooms.soap")}</div>
							</div>
						</div>
						<div className="col-sm mb-2">
							<div className="card-body text-center fs-5 text-white">
							<img src="/images/towels.svg" style={{"width":"30px"}} alt="Towels"/>
								<div>{this.props.c("rooms.towel")}</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm mb-2">
							<div className="card-body text-center fs-5 text-white">
								<img src="/images/hairdryer.svg" style={{"width":"40px"}} alt="Hairdryer"/>
								<div>{this.props.c("rooms.foen")}</div>
							</div>
						</div>
						<div className="col-sm mb-2">
							<div className="card-body text-center fs-5 text-white">
								<i className="fa-solid fa-couch"></i>
								<div>{this.props.c("rooms.sessel")}</div>
							</div>
						</div>
						<div className="col-sm mb-2">
							<div className="card-body text-center fs-5 text-white">
								<i className="fa-solid fa-tv"></i>
								<div>{this.props.c("rooms.tv")}</div>
							</div>
						</div>
						<div className="col-sm mb-2">
							<div className="card-body text-center fs-5 text-white">
							<img src="/images/balkony.svg" style={{"width":"30px"}} alt="Balkony"/>
								<div>{this.props.c("rooms.balkon")}</div>
							</div>
						</div>
						<div className="col-sm mb-2">
							<div className="card-body text-center fs-5 text-white">
							<img src="/images/desk.svg" style={{"width":"40px"}} alt="Desk"/>
								<div>{this.props.c("rooms.desk")}</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container mt-4">
					<div className="row">
						<div className="col-sm mt-2">
							<img src="/images/IMG_5021.jpeg" className="img-fluid" alt="Doppelzimmer" />
						</div>
						<div className="col-sm mt-2 pb-3 bg-light">
							<h2 className="mt-3">{this.props.c("rooms.doppelzimmer")}</h2>
							<div className="fs-5">{this.props.c("rooms.dopplezimmerheadline")}</div>
							<ul className="mt-3 mb-3">
								<li className="hclist">{this.props.c("rooms.doppelbett")}</li>
								<li className="hclist">{this.props.c("rooms.freewlan")}</li>
								<li className="hclist">{this.props.c("rooms.defaultbath")}</li>
								<li className="hclist">{this.props.c("rooms.roomsoap")}</li>
								<li className="hclist">{this.props.c("rooms.clothet")}</li>
								<li className="hclist">{this.props.c("rooms.flatscreen")}</li>
								<li className="hclist">{this.props.c("rooms.armchair")}</li>
								<li className="hclist">{this.props.c("rooms.nightstand")}</li>
							</ul>
							<Link to={"/"+this.props.lang+"/reservation"} className="text-decoration-none">
								<div className="hcbutton"> {this.props.c("home.toreservation")}</div>
							</Link>
						</div>
					</div>
					<div className="row">
						<div className="col-sm mt-2">
							<img src="/images/IMG_0651.jpg" className="img-fluid" alt="Dreibettzimmer" />
						</div>
						<div className="col-sm mt-2 pb-3 bg-light">
							<h2 className="mt-3">{this.props.c("rooms.dreibettzimmer")}</h2>
							<div className="fs-5">{this.props.c("rooms.dreibettzimmerheadline")}</div>
							<ul className="mt-3 mb-3">
								<li className="hclist">{this.props.c("rooms.doppelbett")} + {this.props.c("rooms.einzelbett")}</li>
								<li className="hclist">{this.props.c("rooms.freewlan")}</li>
								<li className="hclist">{this.props.c("rooms.defaultbath")}</li>
								<li className="hclist">{this.props.c("rooms.roomsoap")}</li>
								<li className="hclist">{this.props.c("rooms.clothet")}</li>
								<li className="hclist">{this.props.c("rooms.flatscreen")}</li>
								<li className="hclist">{this.props.c("rooms.armchair")}</li>
								<li className="hclist">{this.props.c("rooms.nightstand")}</li>
							</ul>
							<Link to={"/"+this.props.lang+"/reservation"} className="text-decoration-none">
								<div className="hcbutton"> {this.props.c("home.toreservation")}</div>
							</Link>
						</div>
					</div>
					<div className="row">
						<div className="col-sm mt-2">
							<img src="/images/IMG_5179.jpg" className="img-fluid" alt="Vierbettzimmer" />
						</div>
						<div className="col-sm mt-2 pb-3 bg-light">
							<h2 className="mt-3">{this.props.c("rooms.vierbettzimmer")}</h2>
							<div className="fs-5">{this.props.c("rooms.vierbettzimmerheadline")}</div>
							<ul className="mt-3 mb-3">
								<li className="hclist">{this.props.c("rooms.doppelbett")} + 2 {this.props.c("rooms.einzelbetten")}</li>
								<li className="hclist">{this.props.c("rooms.freewlan")}</li>
								<li className="hclist">{this.props.c("rooms.defaultbath")}</li>
								<li className="hclist">{this.props.c("rooms.roomsoap")}</li>
								<li className="hclist">{this.props.c("rooms.clothet")}</li>
								<li className="hclist">{this.props.c("rooms.flatscreen")}</li>
								<li className="hclist">{this.props.c("rooms.armchair")}</li>
								<li className="hclist">{this.props.c("rooms.nightstand")}</li>
							</ul>
							<Link to={"/"+this.props.lang+"/reservation"} className="text-decoration-none">
								<div className="hcbutton"> {this.props.c("home.toreservation")}</div>
							</Link>
						</div>
					</div>
					<div className="row">
					<div className="col-sm mt-2">
							<img src="/images/IMG_0968.jpg" className="img-fluid" alt="Einzelnzimmer" />
						</div>
						<div className="col-sm mt-2 pb-3 bg-light">
							<h2 className="mt-3">{this.props.c("rooms.einzelzimmer")}</h2>
							<div className="fs-5">{this.props.c("rooms.einzelzimmerheadline")}</div>
							<ul className="mt-3 mb-3">
							<li className="hclist">{this.props.c("rooms.einzelbett")}</li>
								<li className="hclist">{this.props.c("rooms.freewlan")}</li>
								<li className="hclist">{this.props.c("rooms.defaultbath")}</li>
								<li className="hclist">{this.props.c("rooms.roomsoap")}</li>
								<li className="hclist">{this.props.c("rooms.clothet")}</li>
								<li className="hclist">{this.props.c("rooms.flatscreen")}</li>
								<li className="hclist">{this.props.c("rooms.armchair")}</li>
								<li className="hclist">{this.props.c("rooms.nightstand")}</li>
							</ul>
							<Link to={"/"+this.props.lang+"/reservation"} className="text-decoration-none">
								<div className="hcbutton"> {this.props.c("home.toreservation")}</div>
							</Link>
						</div>
					</div>
					<div className="row">
						<div className="col-sm mt-2">
							<img src="/images/IMG_6410.jpg" className="img-fluid" alt="Doppelzimmer" />
						</div>
						<div className="col-sm mt-2 pb-3 bg-light">
							<h2 className="mt-3">{this.props.c("rooms.ecodoppelzimmer")}</h2>
							<div className="fs-5">{this.props.c("rooms.ecodoppelzimmerheadline")}</div>
							<ul className="mt-3 mb-3">
								<li className="hclist">{this.props.c("rooms.doppelbett")}</li>
								<li className="hclist">{this.props.c("rooms.freewlan")}</li>
								<li className="hclist">{this.props.c("rooms.ecobath")}</li>
								<li className="hclist">{this.props.c("rooms.roomsoap")}</li>
								<li className="hclist">{this.props.c("rooms.clothet")}</li>
								<li className="hclist">{this.props.c("rooms.flatscreen")}</li>
								<li className="hclist">{this.props.c("rooms.armchair")}</li>
								<li className="hclist">{this.props.c("rooms.nightstand")}</li>
							</ul>
							<Link to={"/"+this.props.lang+"/reservation"} className="text-decoration-none">
								<div className="hcbutton"> {this.props.c("home.toreservation")}</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Rooms;
