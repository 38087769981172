import React from "react";
import { MapContainer, TileLayer, Polygon, Marker, Popup } from 'react-leaflet'
import * as L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import Carousel from 'better-react-carousel'


class Location extends React.Component {

	render() {

		L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

		const fillBlueOptions = { fillColor: 'blue' };
		const hotelPosition = [52.5001968, 13.3147386];
		const mapCenterPosition = [52.5002968, 13.3147386];
		const polygon = [
			[52.50025, 13.314765],
			[52.50027, 13.31444],
			[52.500236, 13.31432],
			[52.50023, 13.31427],
			[52.50016, 13.31425],
			[52.50015, 13.31431],
			[52.50017, 13.31432],
			[52.50018, 13.31438],
			[52.50019, 13.31438],
			[52.50021, 13.31446],
			[52.5002, 13.31452],
			[52.50019, 13.31452],
			[52.500151, 13.31451],
			[52.50015, 13.314557],
			[52.5001, 13.31455],
			[52.50009, 13.314745]
		];
		document.title = this.props.c("location.title");
		return (
			<div>
				<div className="container text-center">
					<h1>{this.props.c("location.headline")}</h1>
				</div>
				<div className="text-center fs-4">
					{this.props.c("location.lage")}
				</div>
				<div className="text-center fs-4 mt-2">
				<Carousel cols={3} rows={1} gap={0} loop>
					<Carousel.Item>
						<img width="100%" src="/images/pictures/Location-06.jpg" alt="" />
					</Carousel.Item>
					<Carousel.Item>
						<img width="100%" src="/images/pictures/Location-02.jpg" alt="" />
					</Carousel.Item>
					<Carousel.Item>
						<img width="100%" src="/images/pictures/Location-03.jpg" alt="" />
					</Carousel.Item>
					<Carousel.Item>
						<img width="100%" src="/images/pictures/Location-05.jpg" alt="" />
					</Carousel.Item>
					<Carousel.Item>
						<img width="100%" src="/images/pictures/Location-06.jpg" alt="" />
					</Carousel.Item>
					<Carousel.Item>
						<img width="100%" src="/images/pictures/Location-04.jpg" alt="" />
					</Carousel.Item>
				</Carousel>
				</div>
				<MapContainer className="mt-4" style={{"width":"100%", "height":"600px"}} id="map" center={mapCenterPosition} zoom={18} scrollWheelZoom={false} gestureHandling={true}>
					<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					/>
					<Polygon pathOptions={fillBlueOptions} positions={polygon} />
					<Marker position={hotelPosition} >
						<Popup >
							<img src="/images/logo.png" style={{"width":"150px"}} className="img-fluid mb-2" alt="Hotel Castell Berlin Logo"></img>
							<div className="bt-2">
								<span className="fw-bolder">Hotel Castell</span><br/>
								Wielandstr. 24<br/>
								10707 Berlin<br/>
								{this.props.c("footer.deutschland")}
							</div>
							<div className="mt-2 mb-1 fw-bolder">
								{this.props.c("location.navigation")}
							</div>
							<div className="d-flex">
								<div className="text-center">
									<a href="https://maps.apple.com/?daddr=Wielandstr.+24,Charlottenburg,10707,Berlin,Deutschland&auid=1015413268172626275&ll=52.500237,13.314751&q=Hotel%20Castell&dirflg=d&t=m" target="_blank" rel="noreferrer">
										<img src="/images/maps.png" style={{"width":"54px"}} alt="Maps"></img>
										<br/>
										Maps
									</a>
								</div>
								<div className="text-center">
									<a href="https://www.google.com/maps/dir/?api=1&destination=Hotel+Castell,Wielandstraße+24,10707,Berlin&query=Hotel+Castell&destination_place_id=ChIJh63D8tXAmUcRuF_Q7OpaFBU" target="_blank" rel="noreferrer">
										<img src="/images/googlemaps.png" style={{"width":"54px"}} alt="Goolge Maps"></img>
										<br/>
										Google Maps
									</a>
								</div>
							</div>
						</Popup>
					</Marker>
				</MapContainer>
				<div className="container mt-4">
					<div className="text-center">
						<h2>{this.props.c("location.anreise")}</h2>
					</div>
					<div className="row mt-3">
						<div className="col-sm mt-2 bg-light">
							<div className="mt-4 text-center">
								<h3><i className="fa-solid fa-car"></i></h3>
								<h3>{this.props.c("location.auto")}</h3>
								<hr/>
								<p><span className="badge bg-primary">A 100</span>{this.props.c("location.auto_text")}</p>
								<div className="mt-2 mb-1 fw-bolder">
								{this.props.c("location.navigation")}
								</div>
								<div className="d-inline-flex">
									<div className="text-center p-2">
										<a href="https://maps.apple.com/?daddr=Wielandstr.+24,Charlottenburg,10707,Berlin,Deutschland&auid=1015413268172626275&ll=52.500237,13.314751&q=Hotel%20Castell&dirflg=d&t=m" target="_blank" rel="noreferrer">
											<img src="/images/maps.png" style={{"width":"54px"}} alt="Maps"></img>
										</a>
									</div>
									<div className="text-center p-2">
										<a href="https://www.google.com/maps/dir/?api=1&destination=Hotel+Castell,Wielandstraße+24,10707,Berlin&query=Hotel+Castell&destination_place_id=ChIJh63D8tXAmUcRuF_Q7OpaFBU" target="_blank" rel="noreferrer">
											<img src="/images/googlemaps.png" style={{"width":"54px"}} alt="Goolge Maps"></img>
										</a>
									</div>
								</div>
								<div className="mt-4 mb-1 fw-bolder">
									{this.props.c("location.umweltzone")}
								</div>
									<p>{this.props.c("location.umweltzone_text")}</p>
								<div className="mb-4">
									<a className="btn btn-outline-secondary" href="https://www.berlin.de/tourismus/infos/1760452-1721039-umweltzone.html" target="_blank" rel="noreferrer">{this.props.c("location.umweltzone_link")}</a>
								</div>
							</div>
						</div>
						<div className="col-sm mt-2 bg-light">
							<div className="mt-4 text-center">
								<h3><i className="fa-solid fa-plane"></i></h3>
								<h3>{this.props.c("location.flugzeug")}</h3>
								<hr/>
								<p>{this.props.c("location.flugzeug_text")}</p>
							</div>
							<div className="mt-2 mb-1 fw-bolder">
								{this.props.c("location.airport_links")}
							</div>
							<ul>
								<li>
									<a href="https://ber.berlin-airport.de/de/fliegen/abfluege-ankuenfte.html" target="_blank" rel="noreferrer">{this.props.c("location.anabflugzeiten")}</a>
									</li>
								<li>
									<a href="https://www.bvg.de/de/verbindungen/verbindungssuche?SID=A%3D1%40O%3DBER%40X%3D13509870%40Y%3D52364610%40U%3D86%40L%3D900260009%40B%3D1%40p%3D1653444609%40&ZID=A%3D2%40O%3D10707%2520Berlin-Charlottenburg%2C%2520Wielandstr.%252024%40X%3D13314921%40Y%3D52500159%40U%3D103%40L%3D770009336%40B%3D1%40p%3D1592347662%40&timeSel=depart&start=1" target="_blank" rel="noreferrer">{this.props.c("location.publictransfer")}</a>
								</li>
								<li>
									<a href="https://ber.berlin-airport.de/de/orientierung/anreiseundabreise/taxi.html" target="_blank" rel="noreferrer">{this.props.c("location.taxiinformation")}</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="row">
						<div className="col-sm mt-2 bg-light">
							<div className="mt-4 text-center">
								<h3><i className="fa-solid fa-bus-simple"></i></h3>
								<h3>{this.props.c("location.bus")}</h3>
								<hr/>
								<p>{this.props.c("location.bus_text")}</p>
								<p>
									<span className="badge" style={{"backgroundColor":"rgb(153, 51, 153)"}}>101</span> <span className="badge" style={{"backgroundColor":"rgb(153, 51, 153)"}}>109</span> <span className="badge" style={{"backgroundColor":"rgb(153, 51, 153)"}}>110</span> <span className="badge" style={{"backgroundColor":"rgb(153, 51, 153)"}}>M19</span> <span className="badge" style={{"backgroundColor":"rgb(153, 51, 153)"}}>M29</span> <span className="badge" style={{"backgroundColor":"rgb(153, 51, 153)"}}>X10</span>
								</p>
							</div>
							<div className="mt-2 mb-1 fw-bolder">
								{this.props.c("location.bus_verbindung_suchen")}
							</div>
							<ul>
								<li>
									<a href="https://www.bvg.de/de/verbindungen/verbindungssuche?ZID=A%3D1%40O%3DOlivaer%2520Platz%2520%28Berlin%29%40X%3D13312997%40Y%3D52500941%40U%3D86%40L%3D900023304%40B%3D1%40p%3D1653444609%40&timeSel=depart&start=1" target="_blank" rel="noreferrer">{this.props.c("location.withbvg")}</a>
								</li>
							</ul>
						</div>
						<div className="col-sm mt-2 bg-light">
							<div className="mt-4 text-center">
								<h3><i className="fa-solid fa-train"></i></h3>
								<h3>{this.props.c("location.train")}</h3>
								<hr/>
								<p>{this.props.c("location.train_text")}</p>
							</div>
							<div className="mt-2 mb-1 fw-bolder">
								{this.props.c("location.train_connections")}
							</div>
							<div>
								<ul>
									<li>
										<a href="https://www.bvg.de/de/verbindungen/verbindungssuche?SID=A%3D1%40O%3DS%2BU%2520Zoologischer%2520Garten%2520Bhf%2520%28Berlin%29%40X%3D13332711%40Y%3D52506919%40U%3D86%40L%3D900023201%40B%3D1%40p%3D1653444609%40&ZID=A%3D2%40O%3D10707%2520Berlin-Charlottenburg%2C%2520Wielandstr.%252024%40X%3D13314921%40Y%3D52500159%40U%3D103%40L%3D770009336%40B%3D1%40p%3D1592347662%40&timeSel=depart&start=1" target="_blank" rel="noreferrer">{this.props.c("location.from_zoo")}</a>
									</li>
									<li>
										<a href="https://www.bvg.de/de/verbindungen/verbindungssuche?SID=A%3D1%40O%3DS%2BU%2520Berlin%2520Hauptbahnhof%40X%3D13369072%40Y%3D52525607%40U%3D86%40L%3D900003201%40B%3D1%40p%3D1653444609%40&ZID=A%3D2%40O%3D10707%2520Berlin-Charlottenburg%2C%2520Wielandstr.%252024%40X%3D13314921%40Y%3D52500159%40U%3D103%40L%3D770009336%40B%3D1%40p%3D1592347662%40&timeSel=depart&start=1" target="_blank" rel="noreferrer">{this.props.c("location.from_hbf")}</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Location;
