import React from "react";
import { Link, useLocation } from "react-router-dom";
import './Header.css';

export function Languages(props) {
	
	const location = useLocation();
	let splittedURL = location.pathname.split("/");
	let page = ""
	if (splittedURL.length > 1) {
		 page = splittedURL[splittedURL.length-1]
	}

	return (
		<div className="">
			{props.lang !== "de"?<Link className="btn" to={"/de/"+page} onClick={() => props.changeLanguage("de")}><span className="fi fi-de"></span></Link>:""}
			{props.lang !== "en"?<Link className="btn" to={"/en/"+page} onClick={() => props.changeLanguage("en")}><span className="fi fi-gb"></span></Link>:""}
		</div>
	)
}


class Header extends React.Component {

	state = {
		shrinkedNav: false
	}

	componentDidMount() {
		window.addEventListener("scroll", this.resizeHeaderOnScroll);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.resizeHeaderOnScroll);
	}

	resizeHeaderOnScroll = () => {
		if (window.pageYOffset > 60) {
			this.setState({ shrinkedNav : true })
		} else if (window.pageYOffset === 0) {
			this.setState({ shrinkedNav : false })
		}
	}

	render() {
		return (
			<nav className="navbar navbar-expand-md navbar-light sticky-top">
				<div className="container-fluid">
					<Link className="navbar-brand" to="/">
						<img src="/images/logo.png" className={"img-fluid "+(this.state.shrinkedNav?"logo-shrinked":"logo")} alt="Hotel Castell Logo"></img>
					</Link>
					<button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
						<div className="offcanvas-header">
							<h5 className="offcanvas-title" id="offcanvasNavbarLabel">Menu</h5>
							<button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
						</div>
						<div className="offcanvas-body">
							<ul className="navbar-nav flex-grow-1 pe-3">
							<li className="nav-item">
								<Link className="nav-link" to={"/"+this.props.lang+"/home"}>{this.props.c("menu.home")}</Link>
								</li>
								<li className="nav-item">
								<Link className="nav-link" to={"/"+this.props.lang+"/rooms"}>{this.props.c("menu.zimmer")}</Link>
								</li>
								<li className="nav-item">
								<Link className="nav-link" to={"/"+this.props.lang+"/service"}>{this.props.c("menu.service")}</Link>
								</li>
								<li className="nav-item">
								<Link className="nav-link" to={"/"+this.props.lang+"/location"}>{this.props.c("menu.lage")}</Link>
								</li>
								<li className="nav-item">
								<Link className="nav-link" to={"/"+this.props.lang+"/reservation"}>{this.props.c("menu.reservierung")}</Link>
								</li>
								<li className="nav-item">
								<Link className="nav-link" to={"/"+this.props.lang+"/contact"}>{this.props.c("menu.kontakt")}</Link>
								</li>
							</ul>
							<div className="">
								<Languages lang={this.props.lang} changeLanguage={this.props.changeLanguage}/>
							</div>
						</div>
						<div className="offcanvas-header">
							<div><a href="tel:+49308827181" className="link-dark text-decoration-none"><i className="bi bi-telephone-fill"></i> +49 30 / 882 71 81</a></div>
						</div>
					</div>
				</div>
			</nav>
		);
	}
}

export default Header;
