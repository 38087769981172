let content = {
	"language": "DE",
	"logo_subtitle": "Alt-Jugendstilhaus direkt am Kurfürstendamm",
	"menu":{
		"home": "Startseite",
		"zimmer": "Zimmer",
		"lage": "Lage",
		"service": "Service",
		"reservierung": "Reservierung",
		"kontakt": "Kontakt"
	},
	"home":{
		"title": "Hotel Castell: Willkommen",
		"headline": "Willkommen im <br> Hotel Castell",
		"instyleof": "im Stil",
		"text": "Ein Haus mit Geschichte - erbaut 1900.",
		"text1": "Bei uns können Sie stilvoll in Berlin nächtigen. Unser gemütliches Hotel ist im Jugendstil erbaut und versprüht nostalgischen Charme. In einer ruhigen Nebenstraße vom Ku´Damm befinden Sie sich mitten in der Metropole und erreichen fußläufig die Geschäftswelt und Sehenswürdigkeiten Berlins.",
		"torooms": "Zu den Zimmern",
		"toservice": "Zum Service",
		"toreservation": "Zur Reservierungsanfrage"
	},
	"rooms":{
		"title": "Hotel Castell: Zimmer",
		"headline": "Zimmer",
		"headertext": "Das Hotel ist im Jugendstil erbaut. Die Zimmer haben hohen Decken und vereinzelt Stuck und Buntglasfenster. Sie erstrecken sich auf vier Etagen und haben einen Blick zum Garten oder zur Innenstadt.",
		"text": "Unser Hotel besteht insgesamt aus 30 Zimmern. Ihnen stehen Einzel-, Doppel-, Dreibett- und Vierbettzimmer zur Verfügung. Bei Bedarf kann ein Zustellbett dazugebucht werden. Die Zimmer sind nach Jugendstilart eingerichtet und mit Bad, WC, TV und Fön ausgestattet. Auf Anfrage kann ein Zimmer mit Balkon reserviert werden.",
		"dusche": "Badezimmer mit Dusche",
		"nichtraucher": "Nichtraucherzimmer",
		"tv": "TV",
		"foen": "Haartrockner",
		"soap": "Seife/Shampoo",
		"sessel": "Sessel",
		"towel": "Bade-/Handtücher",
		"wlan": "Kostenloses WLAN",
		"balkon": "Vereinzelnd Balkon",
		"desk": "Vereinzelnd Schreibtisch",
		"zimmerausstattung": "Zimmerausstattung",
		"doppelzimmer": "Doppelzimmer",
		"dopplezimmerheadline": "Unser gemütliches Standardzimmer",
		"dreibettzimmer": "Dreibettzimmer",
		"dreibettzimmerheadline": "Unser Zimmer für 3 Personen", 
		"vierbettzimmer": "Vierbettzimmer",
		"vierbettzimmerheadline": "Unser Zimmer für Familien oder kleine Gruppen",
		"einzelzimmer": "Einzelzimmer",
		"einzelzimmerheadline": "Unser gemütliches Standardzimmer",
		"ecodoppelzimmer": "Doppelzimmer Economy",
		"ecodoppelzimmerheadline": "Unser Zimmer für Preisbewusste",
		"doppelbett": "Doppelbett",
		"einzelbett": "Einzelbett",
		"einzelbetten": "Einzelbetten",
		"freewlan": "Kostenloses WLAN",
		"roomsoap": "Seife und Shampoo",
		"clothet": "Kleiderschrank",
		"flatscreen": "Flachbildfernseher",
		"armchair": "Sessel",
		"nightstand": "Nachttisch mit Leselampe",
		"ecobath": "Dusche im Zimmer / WC auf dem Gang",
		"defaultbath": "Dusche / WC"
	},
	"location":{
		"title": "Hotel Castell: Lage",
		"headline": "Lage",
		"lage": "Das Hotel befindet sich in unmittelbarer Nähe zum Kurfürstendamm.",
		"anreise": "Anreise",
		"auto": "Anreise mit dem Auto",
		"auto_text": " Abfahrt mit der Nummer 12 Kurfürstendamm",
		"bus": "Anreise mit dem Bus",
		"bus_text": "Die nächste Bushaltestelle ist die 200m entfernte Haltestelle Olivaer Platz. Es verkehren die Linien",
		"bus_verbindung_suchen": "Verbindung suchen",
		"withbvg": "über Berliner Verkehrsbetriebe BVG",
		"flugzeug": "Anreise mit dem Flugzeug",
		"flugzeug_text": "Das Hotel ist vom Flughafen Berlin Brandenburg (BER) mit dem Auto, Taxi oder dem öffentlichen Nachverkehr erreichbar.",
		"airport_links": "Weitere Links",
		"anabflugzeiten": "An-/Abflüge BER",
		"publictransfer": "BVG Verbindung zum Hotel vom BER",
		"taxiinformation": "weitere Informationen zum TAXI",
		"train": "Anreise mit dem Zug",
		"train_text": "Das Hotel ist mit den TAXI und den öffentlichen Verkehrsmitteln von den Fernverkehrsbahnhöfen Berlins erreichbar. Der nächstgelegene Fernverkehrsbahnhof ist der Zoologische Garten. Der nächste S-Bahnhof ist der S-Savignyplatz.",
		"train_connections": "Verbindungen",
		"from_zoo": "BVG Verbindung zum Hotel von Zoologischen Garten",
		"from_hbf": "BVG Verbindung zum Hotel von Hauptbahnhof",
		"ubahn": "U-Bahn",
		"ubahn_text": "U1 Uhlandstraße<br>U7 Adenauerplatz",
		"sbahn": "S-Bahn",
		"sbahn_text": "Savignyplatz - Ausgang Schlüterstrasse",
		"umgebung": "In direkter Umgebung",
		"restaurants": "Zahlreiche Restaurants",
		"sehenswuerdigkeiten": "Sehenswürdigkeiten wie die Gedächtniskirche",
		"luxusgeschaefte": "Luxusgeschäfte",
		"chanel": "Chanel",
		"louisVuitton": "Louis Vuitton",
		"burburry": "Burburry",
		"rolex": "Rolex",
		"prada": "Prada",
		"longchamp": "Longchamp",
		"mcm": "MCM",
		"navigation": "Navigation",
		"umweltzone": "Umweltzone",
		"umweltzone_text": "Für das Fahren mit dem Auto in Berlin wird eine grüne Umweltplakette benötigt.",
		"umweltzone_link": "Weitere Informationen",
	},
	"service":{
		"title": "Hotel Castell: Service",
		"headline": "Service",
		"headtext": "Wir möchten Ihnen einen angenehmen Aufenthalt in unserem Hotel bereiten.",
		"rezeption": "Rezeption",
		"parkplatz": "Parkplatz",
		"internet": "Internet",
		"woundwann": "Wo und wann",
		"rezeption_text": "Unsere Rezeption befindet sich im 2. Obergeschoss und ist für Sie von 7:30 bis 22:00 Uhr geöffnet, um für Ihre Fragen und Wünsche zur Verfügung zu stehen. Unsere Mitarbeiter können Sie in den Sprachen Deutsch, Englisch und Russisch ansprechen.",
		"rezeptionitem": {
			"time": "Geöffnet von 7:30 bis 22:00 Uhr",
			"location": "2. Obergeschoss",
			"languages": "Unsere Rezeptionisten sprechen",
			"langitems": [{
				"Sprache" : "de",
				"Sprachetext": "Deutsch"
			},{
				"Sprache" : "en",
				"Sprachetext": "Englisch"
			},{
				"Sprache" : "ru",
				"Sprachetext": "Russisch"
			}]
		},
		"parkplatz_text": "Öffentliche Parkplätze können rund um das Hotel und am Kurfürstendamm genutzt werden. Alternativ kann auch das 300m entfernte Parkhaus CONTIPARK Tiefgarage Leibniz Kolonnaden genutzt werden. Die Einfahrt befindet sich in der Leibnizstraße 49-53, 10629 Berlin.",
		"publicparking": "Öffentliche Parkplätze",
		"zone6": "Zone 6",
		"mofr": "Mo - Fr 09:00 - 19:00 0,50 Eur/15 min",
		"sa": "Sa 09:00 - 14:00 0,50 Eur/15 min",
		"so": "So frei",
		"lastChecked": "Stand: 27.10.2024",
		"cityOfBerlin": "Aktuelle Preise auf der Berlin.de Webseite",
		"contipark": "CONTIPARK Tiefgarage Leibniz Kolonnaden",
		"contipreise": "Preise bei CONTIPARK",
		"internet_text": "In allen Zimmern haben Sie kostenlosen WLAN Zugang.",
		"fruehstueck_text": "Von 8:00 bis 10:00 steht Ihnen unser reichhaltiges kontinentales Frühstücksbuffet im 2. Obergeschoss bereit. Zu Kaffee, Tee und Säften bieten wir Ihnen frisches Obst und verschiedene Müslisorten an. Eine Variation an Brot, Wurst- und Käsesorten steht zur Verfügung, damit Sie gestärkt in den Tag starten können. Das Frühstück kostet 12€ pro Person.",
		"fruehstueckitems": {
			"facts": "Fakten",
			"time": "Von 8:00 bis 10:00",
			"location": "2. Obergeschoss",
			"art": "Kontinentales Frühstück",
			"price": "12€ pro Person"
		},
		"gepaeck": "Gepäckaufbewahrung",
		"aufzug": "Aufzug",
		"nichtraucher": "Nichtraucherhotel",
		"wlan": "Kostenloses WLAN",
		"parken": "Öffentliche Parkplätze",
		"fruehstueck": "Frühstücksbuffet",
		"buegeleisen": "Bügeleisen auf Anfrage",
		"extrabed": "Zustellbett auf Anfrage",
		"fan": "Ventilator auf Anfrage",
		"babybed": "Babybett auf Anfrage",
		"freshdrinks": "Gekühlte Getränke",
		"kuehlschrank": "Kühlschrank auf Anfrage",
		"kettle": "Wasserkocher auf Anfrage"
	},
	"reservation":{
		"title": "Hotel Castell: Reservierung",
		"headline": "Anfrage",
		"vorname": "Vorname",
		"name": "Name",
		"anreisedatum": "Anreisedatum",
		"abreisedatum": "Abreisedatum",
		"einzelzimmer": "Einzelzimmer",
		"doppelzimmer": "Doppelzimmer",
		"dreibettzimmer": "Dreibettzimmer",
		"vierbettzimmer": "Vierbettzimmer",
		"fruehstueck": "Mit Frühstück",
		"mail": "E-Mail",
		"tel": "Telefonnummer",
		"extras": "Extra Wünsche",
		"privacypolicy": "Hiermit willige ich ein, dass die hier übermittelten Angaben für die Erstellung eines Angebots genutzt werden dürfen und das Angebot mir per Mail zugesandt wird. Für Rückfragen zum Angebot oder dringliche Angelegenheiten darf das Hotel Castell Berlin mich auch telefonisch kontaktieren. Weitere in Zusammen mit der Seite bestehenden Datenschutzbestimmungen finden Sie unter",
        "anfrageSenden": "Anfrage senden"
	},
	"contact": {
		"title": "Hotel Castell: Kontakt",
		"headline": "Kontakt",
		"hotel": "Hotel Castell",
		"inhaber": "Inhaber",
		"tel": "Telefon",
        "oder": "oder",
		"fax": "Fax",
		"web": "Webseite",
		"mail": "E-Mail",
		"steuerNr": "Steuernummer"
	},
    "thx":{
        "title": "Hotel Castell: Danke",
        "headline": "Danke für Ihre Anfrage!",
        "text": "Wir werden Ihnen in Kürze ein Angebot zusenden!",
		"toHome": "zur Startseite"
    },
	"footer":{
		"impressum":"Impressum",
		"datenschutz": "Datenschutz",
		"deutschland": "Deutschland"
	},
	"privacy":{
		"title": "Datenschutzerklärung"
	}
}

export default function getContentDE() {
	return content;
}