let content = {
	"language": "ES",
	"logo_subtitle": "Alt-Jugendstilhaus direkt am Kurfürstendamm",
	"menu":{
		"zimmer": "Habitaciones",
		"lage": "Ubicación",
		"service": "Servicios",
		"reservierung": "Reservaciones",
		"kontakt": "Contactos"
	},
	"home":{
		"title": "Hotel Castell: Bienvenidos",
		"headline": "Bienvenidos al <br> Hotel Castell",
		"text": "Si usted esta pensando en un alojamiento de primera clase en la ciudad de Berlin, el Hotel Castell es su direccion. Nuestro hotel no solo es excepcionalmente confortable sino tambien construido en el estilo romantico de Art-Noveau con aire de nostalgia encantadora. Situado en un extremo de la calle del famoso Kudamm, usted estara alojado justamente en el centro de la metropoli con acceso al distrito de negocios asi como a las mejores visitas de Berlin a solamente unos pasos de su alcance."
	},
	"rooms":{
		"title": "Hotel Castell: Habitaciones",
		"headline": "Habitaciones",
		"text": "Nuestro hotel ofrece 30 habitaciones que pueden ser: sencillas , dobles, twin y hasta de tres y cuatro camas. Si usted necesita de una cama extra nuestro equipo de trabajo se la facilitará. Las habitaciones están decoradas en estilo Art Nouveau y equipadas con baño, WC, TV, teléfono y secadora. Una habitación con balcón puede ser reservada a su petición."
	},
	"location":{
		"title": "Hotel Castell: Ubicación",
		"headline": "Ubicación",
		"anreise": "Como llegar",
		"auto": "En automóvil",
		"auto_text": "A100 salida Kurfürstendamm",
		"bus": "En autobús",
		"bus_text": "Rutas  101, 109, 110, M19, M29, X10 Olivaer Platz",
		"ubahn": "En metro",
		"ubahn_text": "U1 Uhlandstraße<br>U7 Adenauerplatz",
		"sbahn": "En tren suburbano",
		"sbahn_text": "Savignyplatz - salida Schlüterstrasse",
		"umgebung": "Muy cerca",
		"restaurants": "Un número exclusivo de restaurantes",
		"sehenswuerdigkeiten": "Vistas famosas por ejemplo el Gedächtniskirche",
		"luxusgeschaefte": "Facilidades de compras de lujo",
		"chanel": "Chanel",
		"louisVuitton": "Louis Vuitton",
		"burburry": "Burburry",
		"rolex": "Rolex",
		"prada": "Prada",
		"longchamp": "Longchamp",
		"mcm": "MCM"
	},
	"service":{
		"title": "Hotel Castell: Servicios",
		"headline": "Servicios",
		"rezeption": "Recepción",
		"parkplatz": "Facilidades de parqueo",
		"internet": "Internet",
		"fruehstueck": "Desayuno",
		"rezeption_text": "Ofrecemos servicios de recepción disponible las 24 horas del día. Deseamos ayudarlo en con todas sus preguntas y pedidos.",
		"parkplatz_text": "Nuestro hotel ofrece adhesivos para cargos extra que muestran que los clientes ya han pagado, disponible para  la zona 6 de parqueo gratuito.",
		"internet_text": "Todas las habitaciones disponen de acceso LAN, el mismo puede ser solicitado por un precio módico.",
		"fruehstueck_text": "A partir de las 7.30 hasta las 10am usted puede disfrutar de un rico desayuno buffet que ofrece todo lo que usted necesita para comenzar el día."
	},
	"reservation":{
		"title": "Hotel Castell: Reservaciones",
		"headline": "Solicitud",
		"vorname": "Primer nombre",
		"name": "Nombre de familia",
		"anreisedatum": "Fecha de entrada",
		"abreisedatum": "Fecha de salida",
		"einzelzimmer": "Habitación sencilla",
		"doppelzimmer": "Habitación doble",
		"dreibettzimmer": "Habitación de tres camas",
		"vierbettzimmer": "Habitación de cuatro camas",
		"fruehstueck": "Desayuno",
		"mail": "Correo electrónico",
		"tel": "Número de teléfono",
		"extras": "Requerimientos especiales",
		"privacypolicy": "I agree that the provided data are submitted to Hotel Castell to create an offer and the offer will be sent to me by mail. For any queries about the offer or urgent matters, the Hotel Castell Berlin can also contact me by phone. For more privacy related to the site, see",
        "anfrageSenden": "Enviar pregunta"
	},
	"contact": {
		"title": "Hotel Castell: Contactos",
		"headline": "Contactos",
		"hotel": "Hotel Castell",
		"inhaber": "Dueno",
		"tel": "Telefono",
        "oder": "or",
		"fax": "Fax",
		"web": "Sitio web",
		"mail": "E-Mail",
		"steuerNr": "Numero de impuesto"
	},
    "thx":{
        "title": "Hotel Castell: Gracias",
        "headline": "Gracias por su consulta!",
        "text": "Nosotros les enviaremos una oferta pronto!"
    },
	"footer":{
		"impressum":"Imprimir",
		"datenschutz": "Privacidad",
		"deutschland": "Alemania"
	}
}
export default function getContentES () {
	return content;
};