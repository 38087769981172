let content = {
	"language": "DE",
	"logo_subtitle": "Alt-Jugendstilhaus direkt am Kurfürstendamm",
	"menu":{
		"zimmer": "Huoneet",
		"lage": "Sijainti",
		"service": "Palvelut",
		"reservierung": "Varaukset",
		"kontakt": "Yhteys"
	},
	"home":{
		"title": "Hotel Castell: Tervetuloa",
		"headline": "Tervetuloa <br> Hotel Castelliin",
		"text": "Tarjoamme viihtyisää oleskelua keskellä Berliiniä.  Tyylikäs hotellimme sijaitsee rauhallisella sivukadulla vain muutaman metrin päässä Kurfürstendammista, joka on kuuluisa monista ostosmahdollisuuksistaan, kulttuuritarjonnastaan ja nähtävyyksistään."
	},
	"rooms":{
		"title": "Hotel Castell: Huoneet",
		"headline": "Huoneet",
		"text": "Hotellimme koostuu yhteensä 30 huoneesta. Tarjoamme yhden, kahden, kolmen ja neljän hengen huoneita. Tarpeen mukaan voi lisäksi tilata yhden lisävuoteen. Huoneet ovat sisustettu jugendtyyliin ja sisältävät kylpyhuoneen, tv ja hiustenkuivaajan. Tarjolla on myös huoneita parvekkeen kanssa, joista pyydämme erityistä varausta."
	},
	"location":{
		"title": "Hotel Castell: Sijainti",
		"headline": "Sijainti",
		"anreise": "Tulo Hotel Castelliin",
		"auto": "Auto",
		"auto_text": "A100 ulosajo Kurfürstendamm",
		"bus": "Bussit",
		"bus_text": "101, 109, 110, M19, M29, X10 Olivaer Platz",
		"ubahn": "U-Bahn",
		"ubahn_text": "U1 Uhlandstraße<br>U7 Adenauerplatz",
		"sbahn": "S-Bahn",
		"sbahn_text": "Savignyplatz - ulos Schlüterstrasse",
		"umgebung": "Ympäristössä",
		"restaurants": "lukuisia ravintoloita",
		"sehenswuerdigkeiten": "nähtävyyksiä esim. Keisari Vilhelmin muistokirkko",
		"luxusgeschaefte": "luksuskauppoja  esim.",
		"chanel": "Chanel",
		"louisVuitton": "Louis Vuitton",
		"burburry": "Burburry",
		"rolex": "Rolex",
		"prada": "Prada",
		"longchamp": "Longchamp",
		"mcm": "MCM"
	},
	"service":{
		"title": "Hotel Castell: Palvelut",
		"headline": "Palvelu",
		"rezeption": "Vastaanotto",
		"parkplatz": "Autopaikoitus",
		"internet": "Internet",
		"fruehstueck": "Aamiainen",
		"rezeption_text": "Rezeption on avoinna 24 tuntia vastaanottamaan teidän kysymykset ja toiveet.",
		"parkplatz_text": "Rezeptionista voi ostaa paikoitusvinjettejä, joilla voi maksutta pysäköidä hotellimme läheisyydessä (vyöhyke 6).",
		"internet_text": "Ilmainen Internet-yhteys kaikissa huoneissa.",
		"fruehstueck_text": "7:30 - 10:00 monipuolinen aamupala seisovasta pöydästä viihtyisessä aamiaishuoneessa."
	},
	"reservation":{
		"title": "Hotel Castell: Varaukset",
		"headline": "Tiedustelu",
		"vorname": "Etunimi",
		"name": "Sukunimi",
		"anreisedatum": "Saapumispäivä",
		"abreisedatum": "Lähtöpaivä",
		"einzelzimmer": "Yhden hengen huone",
		"doppelzimmer": "Kahden hengen huone",
		"dreibettzimmer": "Kolmen hengen huone",
		"vierbettzimmer": "Neljän hengen huone",
		"fruehstueck": "Aamiaisen kanssa",
		"mail": "Sähköposti",
		"tel": "Puhelin",
		"extras": "Erikoistoivomukset",
		"privacypolicy": "I agree that the provided data are submitted to Hotel Castell to create an offer and the offer will be sent to me by mail. For any queries about the offer or urgent matters, the Hotel Castell Berlin can also contact me by phone. For more privacy related to the site, see",
        "anfrageSenden": "lähetä tiedustelu"
	},
	"contact": {
		"title": "Hotel Castell: Yhteys",
		"headline": "Yhteys",
		"hotel": "Hotel Castell",
		"inhaber": "Omistaja",
		"tel": "Puhelin",
        "oder": "tai",
		"fax": "Faksi",
		"web": "Verkkosivu",
		"mail": "Sähköposti",
		"steuerNr": "Veronumero"
	},
    "thx":{
        "title": "Hotel Castell: Kiitos",
        "headline": "Kiitos tiedustelusta!",
        "text": "Lähetämme teille pian tarjouksen!"
    },
	"footer":{
		"datenschutz": "Privacy policy",
		"impressum": "Imprint",
		"deutschland": "Saksa"
	},
	"privacy":{
		"title": "Privacy policy"
	}
}
export default function getContentFI () {
	return content;
};