import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

class Layout extends React.Component {
	render() {
		return (
			<>
				<Header lang={this.props.lang} changeLanguage={this.props.changeLanguage} c={this.props.c} />

				<Outlet />

				<Footer lang={this.props.lang} c={this.props.c} />
			</>
		)
	}
};

export default Layout;