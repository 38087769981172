import React from "react";


class Contact extends React.Component {

	render() {

		document.title = this.props.c("contact.title");

		return (
			<div className="container">
				<h1 className="text-center">{this.props.c("contact.headline")}</h1>
				<div className="row">
					<div className="col-sm mt-2">
						<img src="/images/pictures/Contact-1.jpg" className="img-fluid" alt="Contact Images" />
					</div>
					<div className="col-sm mt-4">
						<h3>{this.props.c("contact.hotel")}</h3>
						<p>{this.props.c("contact.inhaber")} Dr. F. Slavin<br />
						Wielandstr. 24<br />
						10707 Berlin-Charlottenburg<br/>
						{this.props.c("footer.deutschland")}</p>
						<p>
							<i className="bi bi-telephone-fill"></i> {this.props.c("contact.tel")}: <a href="tel:+49308827181">+49 30 / 882 71 81</a><br />
							<i className="bi bi-printer"></i> {this.props.c("contact.fax")}: 030 / 881 55 48<br />
							<i className="bi bi-globe"></i> {this.props.c("contact.web")}: <a href="https://hotelcastell-berlin.de">www.hotelcastell-berlin.de</a><br />
							<i className="bi bi-envelope"></i> {this.props.c("contact.mail")}: <a href="mailto:rezeption@hotelcastell-berlin.de">rezeption@hotelcastell-berlin.de</a></p>
						<p>{this.props.c("contact.steuerNr")}: 13/536/00677</p>
					</div>
				</div>
			</div>
		)
	}
}

export default Contact;
