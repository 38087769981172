let content = {
	"language": "EN",
	"logo_subtitle": "Alt-Jugendstilhaus direkt am Kurfürstendamm",
	"menu":{
		"home": "Home",
		"zimmer": "Rooms",
		"lage": "Location",
		"service": "Service",
		"reservierung": "Reservation",
		"kontakt": "Contact"
	},
	"home":{
		"title": "Hotel Castell: Welcome",
		"headline": "Welcome to <br> Hotel Castell",
		"instyleof": "in style of",
		"text": "A house with history - built 1900.",
		"text1": "Our hotel is not just uniquely comfortable but also built in the romantic style of Art Nouveau with an air of nostalgic charm. Situated in a quiet side street from the famous Ku’damm, you are staying right in the centre of a metropolis with access to the business district as well as Berlin’s major sights within walking distance only.",
		"torooms": "to rooms",
		"toservice": "to service",
		"toreservation": "reservation request"
	},
	"rooms":{
		"title": "Hotel Castell: Rooms",
		"headline": "Rooms",
		"headertext": "The hotel was built in Art Nouveau style. The rooms have high ceilings and some have stucco and stained glass windows. The rooms are distributed over 4 floors and have a city or garden view.",
		"text": "Our hotel offers 30 rooms that are either single, double, twin, three or four-bed rooms. If you require an extra bed, our staff will take care of that as well. The rooms are decorated in the style of Art Nouveau and equipped with a bathroom, WC, TV and hairdryer. A room with a balcony can be booked on request.",
		"dusche": "Bathroom with shower",
		"nichtraucher": "Non smoking rooms",
		"tv": "TV",
		"foen": "Hair dryer",
		"soap": "Soap/Shampoo",
		"sessel": "Armchair",
		"towel": "Towels",
		"wlan": "Free WLAN",
		"balkon": "Balcony in some rooms",
		"desk": "Desk in some rooms",
		"zimmerausstattung": "Room features",
		"doppelzimmer": "Double room",
		"dopplezimmerheadline": "Our comfortable standard room",
		"dreibettzimmer": "Triple room",
		"dreibettzimmerheadline": "Our room for 3 persons", 
		"vierbettzimmer": "Quadruple room",
		"vierbettzimmerheadline": "Our room for families and small groups",
		"einzelzimmer": "Single room",
		"einzelzimmerheadline": "Our standard room for one person",
		"ecodoppelzimmer": "Economy double room",
		"ecodoppelzimmerheadline": "Our room for price-conscious persons",
		"doppelbett": "Double bed",
		"einzelbett": "Single bed",
		"einzelbetten": "single beds",
		"freewlan": "Free WLAN",
		"roomsoap": "Soap and shampoo",
		"clothet": "Closet",
		"flatscreen": "Flatscreen TV",
		"armchair": "Armchair",
		"nightstand": "Bedside table with reading light",
		"ecobath": "Shower in room / WC in coridor",
		"defaultbath": "Shower / WC"
	},
	"location":{
		"title": "Hotel Castell: Location",
		"headline": "Location",
		"lage": "The hotel is located next to the famous Kurfürstendamm.",
		"anreise": "Arrival",
		"auto": "Arrival by car",
		"auto_text": " exit number 12 Kurfürstendamm",
		"bus": "Arrival by bus",
		"bus_text": "The next bus stop is Olivaer Platz 200m away. You find the lines",
		"bus_verbindung_suchen": "Find connection",
		"withbvg": "with Berliner Verkehrsbetriebe BVG",
		"flugzeug": "Arrival by airplane",
		"flugzeug_text": "The hotel is arrivable from the airport Berlin Brandenburg (BER) by car, TAXI or the public transport.",
		"airport_links": "Further links",
		"anabflugzeiten": "Arrival / departure BER",
		"publictransfer": "BVG connection to hotel from BER",
		"taxiinformation": "Further information for TAXI",
		"train": "Arrival by train",
		"train_text": "The hotel is arrivable from train stations by TAXI or the public transport. The next train station is Zoologische Garten. The next S train station is S-Savignyplatz.",
		"train_connections": "Connections",
		"from_zoo": "BVG connection to hotel from Zoologischen Garten",
		"from_hbf": "BVG connection to hotel from central station (Hauptbahnhof)",
		"ubahn": "Arrival by subway",
		"ubahn_text": "U1 Uhlandstraße<br>U7 Adenauerplatz",
		"sbahn": "Arrival by suburban train",
		"sbahn_text": "Savignyplatz - exit Schlüterstrasse",
		"umgebung": "Nearby",
		"restaurants": "a number of exclusive restaurants",
		"sehenswuerdigkeiten": "famous sights, e.g. the Gedächtniskirche",
		"luxusgeschaefte": "luxurious shopping facilities",
		"chanel": "Chanel",
		"louisVuitton": "Louis Vuitton",
		"burburry": "Burburry",
		"rolex": "Rolex",
		"prada": "Prada",
		"longchamp": "Longchamp",
		"mcm": "MCM",
		"navigation": "Navigation",
		"umweltzone": "Environmental zone",
		"umweltzone_text": "You need a green environmental badge for driving a car in Berlin.",
		"umweltzone_link": "Further information",
	},
	"service":{
		"title": "Hotel Castell: Service",
		"headline": "Service",
		"headtext": "We want you to have a wonderful and pleasant stay.",
		"rezeption": "Reception",
		"parkplatz": "Parking facilities",
		"internet": "Internet",
		"rezeption_text": "The reception is located on the 2. floor and is open from 7:30 am to 22:00 pm to help you with questions and wishes. Our receptionists speak German, English and Russian.",
		"rezeptionitem": {
			"time": "Open from 7:30 to 22:00",
			"location": "2. floor",
			"languages": "All receptionists speak",
			"langitems": [{
				"Sprache" : "de",
				"Sprachetext": "German"
			},{
				"Sprache" : "en",
				"Sprachetext": "English"
			},{
				"Sprache" : "ru",
				"Sprachetext": "Russian"
			}]
		},
		"parkplatz_text": "You can find public parklinglots around the hotel and on the Kurfürstendamm. Alternativly you can use the underground parking CONTIPARK Tiefgarage Leibniz Kolonnaden 300m from the hotel. The entrance is located at Leibnizstraße 49-53, 10629 Berlin.",
		"publicparking": "Public Parking",
		"zone6": "Zone 6",
		"mofr": "Mo - Fr 09:00 - 19:00 0.50 Eur/15 min",
		"sa": "Sa 09:00 - 14:00 0.50 Eur/15 min",
		"so": "Su free",
		"lastChecked": "last checked: 27th October 2024",
		"cityOfBerlin": "City website berlin.de for current costs",
		"contipark": "CONTIPARK Tiefgarage Leibniz Kolonnaden",
		"contipreise": "Prices by CONTIPARK",
		"internet_text": "In allen Zimmern haben Sie kostenlosen WLAN Zugang.",
		"woundwann": "Where and when",
		"fruehstueck_text": "Our continental breakfast buffet is served on 2. floor from 8:00 am to 10:00 am . We provide coffee, tea and juis together with different cerials and fresh fruits. A further variation of bread, sausages and chees is the basis for a good start to the day. The price is 12€ pro person.",
		"fruehstueckitems": {
			"facts": "Key facts",
			"time": "From 8:00 to 10:00",
			"location": "2. floor",
			"art": "Continental breakfast",
			"price": "12€ per person"
		},
		"gepaeck": "Luggage storage",
		"aufzug": "Elevator",
		"nichtraucher": "Non smoking hotel",
		"wlan": "Free WLAN",
		"parken": "Public Parking",
		"fruehstueck": "Breakfast buffet",
		"buegeleisen": "Iron on request",
		"extrabed": "Extrabed on request",
		"fan": "Fan on request",
		"babybed": "Babybed on request",
		"freshdrinks": "Cooled baverages",
		"kuehlschrank": "Refrigerator on request",
		"kettle": "Kettle on request"
	},
	"reservation":{
		"title": "Hotel Castell: Reservation",
		"headline": "Request",
		"vorname": "first name",
		"name": "last name",
		"anreisedatum": "date of arrival",
		"abreisedatum": "date of departure",
		"einzelzimmer": "single room",
		"doppelzimmer": "double room",
		"dreibettzimmer": "triple room",
		"vierbettzimmer": "quadruple room",
		"fruehstueck": "breakfast",
		"mail": "e-mail",
		"tel": "telephone number",
		"extras": "special requirements",
		"privacypolicy": "I agree that the provided data are submitted to Hotel Castell to create an offer and the offer will be sent to me by mail. For any queries about the offer or urgent matters, the Hotel Castell Berlin can also contact me by phone. For more privacy related to the site, see ",
        "anfrageSenden": "Send Request"
	},
	"contact": {
		"title": "Hotel Castell: Contact",
		"headline": "Contact",
		"hotel": "Hotel Castell",
		"inhaber": "Owner",
		"tel": "Telephone",
        "oder": "or",
		"fax": "Fax",
		"web": "Website",
		"mail": "Mail",
		"steuerNr": "Taxnumber"
	},
    "thx":{
        "title": "Hotel Castell: Thanks",
        "headline": "Thank you for your request!",
        "text": "We will send you an offer soon!",
		"toHome": "to home"
    },
	"footer":{
		"impressum":"Imprint",
		"datenschutz": "Privacy policy",
		"deutschland": "Germany"
	}
}

export default function getContentEN () {
	return content;
};