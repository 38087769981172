import React from "react";
import { Link } from "react-router-dom";

class Reservation extends React.Component {

	render() {
		document.title = this.props.c("reservation.title");
		return (
			<div className="container">
				<div className="text-center">
					<h1>{this.props.c("reservation.headline")}</h1>
				</div>
				<div>
					<form autoComplete="on" name="reservierung" action="https://hotelcastell-berlin.de/formmail.php" method="post" acceptCharset="utf-8">
						<input type="hidden" name="recipients" value="rezeption@hotelcastell-berlin.de" />
						<input type="hidden" name="subject" value="Reservierungsanfrage Hotel Castell Berlin" />
						<input type="hidden" name="required" value="lastname:Last Name,phone:Phone number,email:E-Mail address,anreise:Date of arrival,abreise:Date of departure,privacypolicy" />
						<input type="hidden" name="good_url" value={"http://www.hotelcastell-berlin.de/"+this.props.lang+"/thx"} />
						<input type="hidden" name="derive_fields" value="realname=firstname+lastname,Gesendet=%dom0%.%'-'%.%moy0%.%'-'%.%year%+%'um'%+%time%" />
						<input type="hidden" name="mail_options" value="CharSet=UTF-8" />
						<input type="hidden" name="Sprache" value={this.props.lang} />
						<div className="row">
							<div className="col mb-3">
								<label forhtml="anreise" className="form-label">{this.props.c("reservation.anreisedatum")}<span className="text-danger">*</span></label>
								<input type="date" className="form-control" id="anreise" name="anreise" required />
							</div>
							<div className="col mb-3">
								<label forhtml="abreise" className="form-label">{this.props.c("reservation.abreisedatum")}<span className="text-danger">*</span></label>
								<input type="date" className="form-control" id="abreise" name="abreise" required />
							</div>
						</div>
						<div className="row">
							<div className="col mb-3">
								<label forhtml="einzelzimmer" className="form-label">{this.props.c("reservation.einzelzimmer")}</label>
								<select className="form-control" id="einzelzimmer" name="Einzelzimmer">
									<option>0</option>
									<option>1</option>
									<option>2</option>
									<option>3</option>
									<option>4</option>
									<option>5</option>
								</select>
							</div>
							<div className="col mb-3">
								<label forhtml="doppelzimmer" className="form-label">{this.props.c("reservation.doppelzimmer")}</label>
								<select className="form-control" id="doppelzimmer" name="Doppelzimmer">
									<option>0</option>
									<option>1</option>
									<option>2</option>
									<option>3</option>
									<option>4</option>
									<option>5</option>
								</select>
							</div>
							<div className="col mb-3">
								<label forhtml="dreibettzimmer" className="form-label">{this.props.c("reservation.dreibettzimmer")}</label>
								<select className="form-control" id="dreibettzimmer" name="Dreibettzimmer">
									<option>0</option>
									<option>1</option>
									<option>2</option>
									<option>3</option>
									<option>4</option>
									<option>5</option>
								</select>
							</div>
							<div className="col mb-3">
								<label forhtml="vierbettzimmer" className="form-label">{this.props.c("reservation.vierbettzimmer")}</label>
								<select className="form-control" id="vierbettzimmer" name="Vierbettzimmer">
									<option>0</option>
									<option>1</option>
									<option>2</option>
									<option>3</option>
									<option>4</option>
									<option>5</option>
								</select>
							</div>
						</div>
						<div className="mb-3 form-check">
							<input type="checkbox" className="form-check-input" id="fruehstueck" name="Fruehstueck" />
							<label className="form-check-label" forhtml="fruehstueck">{this.props.c("reservation.fruehstueck")}</label>
						</div>
						<div className="mb-3">
							<label forhtml="given-name" className="form-label">{this.props.c("reservation.vorname")}</label>
							<input type="text" className="form-control" id="given-name" name="firstname" />
						</div>
						<div className="mb-3">
							<label forhtml="family-name" className="form-label">{this.props.c("reservation.name")}<span className="text-danger">*</span></label>
							<input type="text" className="form-control" id="family-name" name="lastname" required />
						</div>
						<div className="mb-3">
							<label forhtml="email" className="form-label">{this.props.c("reservation.mail")}<span className="text-danger">*</span></label>
							<input type="email" className="form-control" id="email" name="email" required/>
						</div>
						<div className="mb-3">
							<label forhtml="tel" className="form-label">{this.props.c("reservation.tel")}<span className="text-danger">*</span></label>
							<input type="tel" className="form-control" id="phone" name="phone" />
						</div>
						<div className="mb-3">
							<label forhtml="addons" className="form-label">{this.props.c("reservation.extras")}</label>
							<textarea className="form-control" id="addons" name="Wuensche" rows="5" />
						</div>
						<div className="mb-3 form-check">
							<input type="checkbox" className="form-check-input" id="privacypolicy" name="privacypolicy" required/>
							<label className="form-check-label" forhtml="privacypolicy"><span className="text-danger">*</span> {this.props.c("reservation.privacypolicy")} {<Link to="/privacypolicy">{this.props.c("footer.datenschutz")}</Link>}.</label>
						</div>
						<button type="submit" className="btn btn-primary hcbutton" style={{"width":"auto"}}>{this.props.c("reservation.anfrageSenden")}</button>
					</form>
				</div>
			</div>
		);
	}
}

export default Reservation;
