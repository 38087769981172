import React from "react";


class Thx extends React.Component {

	render() {
		document.title = this.props.c("thx.title");
		return (
			<div className="container">
				<div className="">
					<div className="text-center">
						<i className="bi bi-check-circle text-success fs-1"></i>
						<h3>{this.props.c("thx.headline")}</h3>
						<p>{this.props.c("thx.text")}</p>
						<div>
							<a href="/" className="btn btn-outline-primary rounded-pill">{this.props.c("thx.toHome")}</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Thx;
