let content = {
	"language": "FR",
	"logo_subtitle": "Alt-Jugendstilhaus direkt am Kurfürstendamm",
	"menu":{
		"zimmer": "Chambres",
		"lage": "Emplacement",
		"service": "Service",
		"reservierung": "Réservation",
		"kontakt": "Contacter"
	},
	"home":{
		"title": "Hotel Castell: Bienvenue",
		"headline": "Bienvenue à l’Hôtel Castell",
		"text": "Si vous cherchez de logement en première classe à la ville de Berlin, l’Hôtel Castell est vôtre adresse. Notre hôtel ne porte pas seulement l’exclusivité en confort mais il a été aussi bati en style romantique Art Nouveau avec en air d’une certaine charme nostalgique. Situé complétement dans une petite rue du célèbre Ku’damm, vous êtes logé exactement au centre de la métropole à la portée du quartier de bussiness ainsi qu’aux meilleures vues de Berlin."
	},
	"rooms":{
		"title": "Hotel Castell: Chambres",
		"headline": "Chambres",
		"text": "Notre hôtel offre 30 chambres à une personne, doubles, á deux lits et avec trios ou quatres pièces. De meme, si vous avez besoin d’un autre lit, notre personnel fera de son mieux. Les chambres sont décorées en style Art Nouveau et elles sont équipées de salles de bains, WC, téle et de sèche-cheuveux. Une chambre avec balcon peut être reservée sur demande."
	},
	"location":{
		"title": "Hotel Castell: Emplacement",
		"headline": "Emplacement",
		"anreise": "Comment y arriver",
		"auto": "En voiture",
		"auto_text": "A 100 sortie Kurfürstendamm",
		"bus": "En bus",
		"bus_text": "lignes 101, 109, 110, M19, M29, X10 O.livaer Platz",
		"ubahn": "En métro",
		"ubahn_text": "U1 Uhlandstraße<br>U7 Adenauerplatz",
		"sbahn": "En train de benlieue",
		"sbahn_text": "Savignyplatz - sortie Schlüterstrasse",
		"umgebung": "Tout près",
		"restaurants": "Des restaurants exclusives",
		"sehenswuerdigkeiten": "Célèbres paysages, ex. Gedächtniskirche",
		"luxusgeschaefte": "Luxueux service de course",
		"chanel": "Chanel",
		"louisVuitton": "Louis Vuitton",
		"burburry": "Burburry",
		"rolex": "Rolex",
		"prada": "Prada",
		"longchamp": "Longchamp",
		"mcm": "MCM"
	},
	"service":{
		"title": "Hotel Castell: Service",
		"headline": "Service",
		"rezeption": "L´Acceuil",
		"parkplatz": "Parking",
		"internet": "Internet",
		"fruehstueck": "Le Petit-Déjeuner",
		"rezeption_text": "La Réception est ouvert 24 heures sur 24 ainsi qu´on serez toujours à votre disposition.",
		"parkplatz_text": "Notre hôtel vous offre des vignettes de parking qui vous permettent de garer votre voiture gratuitement  dans toute la zone 6. Ces vignettes seront vendues à un prix raisonable à la Réception.",
		"internet_text": "Toutes chambres disposent d´un accès WLAN que vous pouvez utiliser à un prix raisonable.",
		"fruehstueck_text": "Chaque matin 7:30 et 10:00 vous pouvez profiter d´un buffet délicieux et copieux pour commencer votre jour en pleine force."
	},
	"reservation":{
		"title": "Hotel Castell: Réservation",
		"headline": "Enquête",
		"vorname": "prénom",
		"name": "prénom",
		"anreisedatum": "date d´arrivée",
		"abreisedatum": "date de départ",
		"einzelzimmer": "chambre individuelle",
		"doppelzimmer": "chambre double",
		"dreibettzimmer": "chambre pour trois",
		"vierbettzimmer": "chambre pour quatre",
		"fruehstueck": "avec petit-déjeuner",
		"mail": "adresse mail",
		"tel": "numéro de téléphone",
		"extras": "remarque particulière",
		"privacypolicy": "I agree that the provided data are submitted to Hotel Castell to create an offer and the offer will be sent to me by mail. For any queries about the offer or urgent matters, the Hotel Castell Berlin can also contact me by phone. For more privacy related to the site, see <a href='/de/privacypolicy/'>Datenschutz</a>.",
        "anfrageSenden": "Envoyer la demande"
	},
	"contact": {
		"title": "Hotel Castell: Contacter",
		"headline": "Contacter",
		"hotel": "Hôtel Castell",
		"inhaber": "Propriétaire",
		"tel": "Téléphone",
        "oder": "ou",
		"fax": "Fax",
		"web": "Site Web",
		"mail": "I-mail",
		"steuerNr": "Numéro de taxi"
	},
    "thx":{
        "title": "Hotel Castell: Merci",
        "headline": " Nous vous remercions pour votre demande!",
        "text": "Vous allez recevoir une réponse le plus vite possible!"
    }
}
export default function getContentFR () {
	return content;
};