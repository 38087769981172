import React from "react";
import { Link } from "react-router-dom";
import './Home.css';


class Home extends React.Component {

	render() {
		document.title = this.props.c("home.title");
		return (
			<>
			<div className="img-container">
				<img src="/images/pictures/Home.jpg" className="img-fluid" alt="view from hotel"></img>
				<div className="img-overlay"></div>
				<div className="img-text text-center" style={{top:"10%", left: "0px", width: "100%", fontSize:"10vw"}}>
					<p>
						Hotel Castell
					</p>
				</div>
				<div className="img-text-1 text-center" style={{top:"35%", left:"0px", width: "100%", fontSize:"4vw"}}>
					<p>
						{this.props.c("home.instyleof")}
					</p>
				</div>
				<div className="img-text-2 text-center" style={{top:"45%", left:"0px", width:"100%", fontSize:"8vw"}}>
					<p>
						Art Nouveau
					</p>
				</div>
			</div>
			<div className="container text-center">
				<div className="p-3 fs-2">
					<i className="fa-solid fa-hotel"></i>
				</div>
				<div className="fs-3 mb-3">
					{this.props.c("home.text")}
				</div>
				<div className="">
					{this.props.c("home.text1")}
				</div>
				<div className="row">
					<div className="col-sm mt-3">
						<Link to={"/"+this.props.lang+"/rooms"} className="text-decoration-none text-body">
							<div className="card click">
								<div className="card-body">
									<i className="fa-solid fa-bed fs-2"></i>
									<h5 className="card-title">{this.props.c("home.torooms")}</h5>
								</div>
							</div>
						</Link>
					</div>
					<div className="col-sm mt-3">
						<Link to={"/"+this.props.lang+"/service"} className="text-decoration-none text-body">
							<div className="card click">
								<div className="card-body">
									<i className="fa-solid fa-bell-concierge fs-2"></i>
									<h5 className="card-title">{this.props.c("home.toservice")}</h5>
								</div>
							</div>
						</Link>
					</div>
					<div className="col-sm mt-3">
						<Link to={"/"+this.props.lang+"/reservation"} className="text-decoration-none text-body">
							<div className="card click">
								<div className="card-body">
									<i className="fa-solid fa-book-atlas fs-2"></i>
									<h5 className="card-title">{this.props.c("home.toreservation")}</h5>
								</div>
							</div>
						</Link>
					</div>
				</div>
			</div>
			</>
		);
	}
}

export default Home;