import React from "react";


class Service extends React.Component {

	render() {
		document.title = this.props.c("service.title");
		return (
			<div>
				<div className="container text-center">
					<h1>{this.props.c("service.headline")}</h1>
					<div className="fs-4">
						{this.props.c("service.headtext")}
					</div>
				</div>
				<div className="container-fluid bg-secondary mt-4">
					<div className="row">
						<div className="col-sm mt-2">
							<div className="card-body text-center fs-5 text-white">
								<i className="fa-solid fa-ban-smoking"></i>
								<div>{this.props.c("service.nichtraucher")}</div>
							</div>
						</div>
						<div className="col-sm mt-2">
							<div className="card-body text-center fs-5 text-white">
								<i className="fa-solid fa-mug-saucer"></i>
								<div className="ml-4">{this.props.c("service.fruehstueck")}</div>
							</div>
						</div>
						<div className="col-sm mt-2">
							<div className="card-body text-center fs-5 text-white">
								<i className="fa-solid fa-wifi"></i>
								<div>{this.props.c("service.wlan")}</div>
							</div>
						</div>
						<div className="col-sm mt-2">
							<div className="card-body text-center fs-5 text-white">
								<i className="fa-solid fa-suitcase"></i>
								<div className="ml-4">{this.props.c("service.gepaeck")}</div>
							</div>
						</div>
						<div className="col-sm mt-2">
							<div className="card-body text-center fs-5 text-white">
							<img src="/images/freshdrinks.svg" style={{"width":"20px"}} alt="Fresh drinks"/>
								<div className="ml-4">{this.props.c("service.freshdrinks")}</div>
							</div>
						</div>
						<div className="col-sm mt-2">
							<div className="card-body text-center fs-5 text-white">
								<i className="fa-solid fa-elevator"></i>
								<div>{this.props.c("service.aufzug")}</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm mt-2">
							<div className="card-body text-center fs-5 text-white">
								<i className="fa-solid fa-parking"></i>
								<div className="ml-4">{this.props.c("service.parken")}</div>
							</div>
						</div>
						<div className="col-sm mt-2">
							<div className="card-body text-center fs-5 text-white">
								<img src="/images/iron.svg" style={{"width":"30px"}} alt="hot iron"/>
								<div className="ml-4">{this.props.c("service.buegeleisen")}</div>
							</div>
						</div>
						<div className="col-sm mt-2">
							<div className="card-body text-center fs-5 text-white">
								<i className="fa-solid fa-fan"></i>
								<div className="ml-4">{this.props.c("service.fan")}</div>
							</div>
						</div>
						<div className="col-sm mt-2">
							<div className="card-body text-center fs-5 text-white">
								<img src="/images/refrigerator.svg" style={{"width":"15px"}} alt="refrigerator"/>
								<div className="ml-4">{this.props.c("service.kuehlschrank")}</div>
							</div>
						</div>
						<div className="col-sm mt-2">
							<div className="card-body text-center fs-5 text-white">
								<img src="/images/kettle.svg" style={{"width":"25px"}} alt="kettle"/>
								<div className="ml-4">{this.props.c("service.kettle")}</div>
							</div>
						</div>
						<div className="col-sm mt-2">
							<div className="card-body text-center fs-5 text-white">
								<img src="/images/babybed.svg" style={{"width":"25px"}} alt="babybed"/>
								<div className="ml-4">{this.props.c("service.babybed")}</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row mt-4">
						<div className="col-sm mt-2">
							<img src="/images/pictures/Reception.jpg" className="img-fluid" alt="Rezeption" />
						</div>
						<div className="col-sm mt-2">
							<div className="mt-4 text-center">
								<h3><i className="fa-solid fa-bell-concierge"></i></h3>
								<h3>{this.props.c("service.rezeption")}</h3>
								<hr/>
								<p>{this.props.c("service.rezeption_text")}</p>
							</div>
							{this.props.c("service.woundwann")}
							<ul>
								<li>{this.props.c("service.rezeptionitem.time")}</li>
								<li>{this.props.c("service.rezeptionitem.location")}</li>
							</ul>
							{this.props.c("service.rezeptionitem.languages")}
							<ul>
								{this.props.c("service.rezeptionitem.langitems")!=="" && this.props.c("service.rezeptionitem.langitems").map((item) => (
									<li key={item["Sprache"]}>{item["Sprachetext"]}</li>
								))}
							</ul>
						</div>
					</div>
					<div className="row">
						<div className="col-sm mt-2">
							<img src="/images/pictures/Breakfast.jpg" className="img-fluid" alt="Frühstück" />
						</div>
						<div className="col-sm mt-2">
							<div className="mt-4 text-center">
								<h3><i className="fa-solid fa-mug-saucer"></i></h3>
								<h3>{this.props.c("service.fruehstueck")}</h3>
								<hr/>
								<p>{this.props.c("service.fruehstueck_text")}</p>
							</div>
							{this.props.c("service.fruehstueckitems.facts")}
							<ul>
								<li>{this.props.c("service.fruehstueckitems.time")}</li>
								<li>{this.props.c("service.fruehstueckitems.location")}</li>
								<li>{this.props.c("service.fruehstueckitems.art")}</li>
								<li>{this.props.c("service.fruehstueckitems.price")}</li>
							</ul>
						</div>
					</div>
					<div className="row">
						<div className="col-sm mt-2">
							<img src="/images/pictures/Street_03.jpg" className="img-fluid" alt="Parkplätze" />
						</div>
						<div className="col-sm mt-2">
							<div className="mt-4 text-center">
								<h3><i className="fa-solid fa-parking"></i></h3>
								<h3>{this.props.c("service.parkplatz")}</h3>
								<hr/>
								<p>{this.props.c("service.parkplatz_text")}</p>
							</div>
							<div className="mt-3 mb-1 fw-bolder">
								{this.props.c("service.publicparking")}
							</div>
							<div>{this.props.c("service.zone6")} - {this.props.c("service.lastChecked")}</div>
							<ul>
								<li>
									{this.props.c("service.mofr")}
								</li>
								<li>
									{this.props.c("service.sa")}
								</li>
								<li>
								{this.props.c("service.so")}
								</li>
							</ul>
							<div>
								<a href={"https://www.berlin.de/ba-charlottenburg-wilmersdorf/verwaltung/aemter/ordnung/strassenverkehr-parken/artikel.217586.php"} target="_blank" rel="noreferrer">{this.props.c("service.cityOfBerlin")}</a>
							</div>
							<div className="mt-3 mb-1 fw-bolder">
								 {this.props.c("service.contipark")}
							</div>
							<a href="https://www.mein-contipark.de/parkplatz-finden/parken-in-berlin/tiefgarage-leibniz-kolonnaden-berlin--ci3cp11611?utm_source=uberall&utm_medium=listings&utm_campaign=permanent" target="_blank" rel="noreferrer">{this.props.c("service.contipreise")}</a>
							<div className="mt-4 mb-1 fw-bolder">
								{this.props.c("location.umweltzone")}
							</div>
								<p>{this.props.c("location.umweltzone_text")}</p>
							<div className="mb-4">
								<a className="btn btn-outline-secondary" href={this.props.c("language")==="DE"?"https://www.berlin.de/tourismus/infos/1760452-1721039-umweltzone.html":"https://www.berlin.de/en/tourism/travel-information/1760452-2862820-environmental-zone.en.html"} target="_blank" rel="noreferrer">{this.props.c("location.umweltzone_link")}</a>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		);
	}
}

export default Service;
