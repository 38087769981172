import React from "react";


class Privacypolicy extends React.Component {

	render() {
		document.title = this.props.c("privacy.title");
		return (
			<div className="container">
				<h1 className="text-center">Datenschutzerklärung</h1>
				<h2>Grundlegendes</h2>

				<p>Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art, den Umfang und
				den Zweck der Erhebung und Verwendung personenbezogener Daten durch den Websitebetreiber Dr. Felix Slavin informieren.</p>

				<p>Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Vorschriften. Dadurch neue Technologien und die ständige Weiterentwicklung dieser Webseite Änderungen an dieser Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen sich die Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.</p>

				<p>Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten” oder “Verarbeitung”)
				finden Sie in Art. 4 DSGVO.</p>

				<h2>Zugriffsdaten</h2>
				Der Seitenprovider erhebt aufgrund seines berechtigten Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO) Daten über Zugriffe auf die Website und speichern diese als „Server-Logfiles“ auf dem Server der Website ab. Folgende Daten werden so protokolliert:

				<ul>
					<li>Besuchte Website</li>
					<li>Uhrzeit zum Zeitpunkt des Zugriffes</li>
					<li>Menge der gesendeten Daten in Byte</li>
					<li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
					<li>Verwendeter Browser</li>
					<li>Verwendetes Betriebssystem</li>
					<li>Verwendete IP-Adresse</li>
				</ul>

				Die Server-Logfiles werden für maximal 7 Tage gespeichert und anschließend gelöscht. Die Speicherung der Daten erfolgt aus Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären zu können. Müssen Daten aus Beweisgründen aufgehoben werden, sind sie solange von der Löschung ausgenommen bis der Vorfall endgültig geklärt ist.
			

				<h2>Kontaktformular</h2>

				Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.

				<h2>Rechte des Nutzers</h2>
				<p>Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft darüber zu erhalten,
				welche personenbezogenen Daten über Sie gespeichert wurden. Sie haben außerdem das Recht auf Berichtigung falscher Daten und auf die Verarbeitungseinschränkung oder Löschung Ihrer personenbezogenen Daten. Falls zutreffend, können Sie auch Ihr Recht auf Datenportabilität geltend machen. Sollten Sie annehmen, dass Ihre Daten unrechtmäßig verarbeitet wurden, können Sie eine Beschwerde bei der zuständigen Aufsichtsbehörde einreichen.</p>

				<h3>Löschung von Daten</h3>
				<p>Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten (z. B. Vorratsdatenspeicherung) kollidiert, haben Sie ein Anrecht auf Löschung Ihrer Daten. Von uns gespeicherte Daten werden, sollten sie für ihre Zweckbestimmung nicht mehr vonnöten sein und es keine gesetzlichen Aufbewahrungsfristen geben, gelöscht. Falls eine Löschung nicht durchgeführt werden kann, da die Daten für zulässige gesetzliche Zwecke erforderlich sind, erfolgt eine Einschränkung der Datenverarbeitung. In diesem Fall werden die Daten gesperrt und nicht für andere Zwecke verarbeitet.</p>

				<h3>Widerspruchsrecht</h3>
				<p>Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch machen und der Verarbeitung ihrer personenbezogenen Daten zu jeder Zeit widersprechen. Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten wünschen oder Fragen bzgl. der Erhebung, Verarbeitung oder Verwendung Ihrer personenbezogenen Daten haben oder erteilte
				Einwilligungen widerrufen möchten, wenden Sie sich bitte an folgende E-Mail-Adresse: <a href="mailto:rezeption@hotelcastell-berlin.de">rezeption@hotelcastell-berlin.de</a>.</p>

				<p>Quelle: <a href="www.datenschutz.org">www.datenschutz.org</a></p>
			</div>
		);
	}
}

export default Privacypolicy;
