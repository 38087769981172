import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Rooms from "./Components/Rooms";
import Location from "./Components/Location";
import Service from "./Components/Service";
import Reservation from "./Components/Reservation";
import Contact from "./Components/Contact";
import Privacypolicy from "./Components/Privacypolicy";
import Layout from './Components/Layout/Layout';
import Thx from './Components/Thx';
import "bootstrap-icons/font/bootstrap-icons.css";
import "flag-icons/css/flag-icons.min.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import de from "./Content/de.js"
import en from "./Content/en.js"
import es from "./Content/es.js"
import ru from "./Content/ru.js"
import fr from "./Content/fr.js"
import fi from "./Content/fi.js"

class App extends React.Component {

	state = {
		defaultLanguage: "de",
		currentLanguage: "de"
	}

	componentDidMount() {
		var path = window.location.pathname;
		var currentLanguage = "de";
		if (path.startsWith("/en")) {
			currentLanguage = "en";
		}

		this.setState({ content: {
			de: de(),
			en: en(),
			ru: ru(),
			es: es(),
			fr: fr(),
			fi: fi()
		}, currentLanguage: currentLanguage })
	}

	changeLanguage = (language) => {
		this.setState({ currentLanguage: language })
	}

	getContentForKeyLang = (key, lang) => {
		if ("content" in this.state) {
			let splittedKey = key.split(".")
			let obj = this.state['content'][lang];
			for (var i=0; i < splittedKey.length; i++) {
				if (splittedKey[i] in obj) {
					obj = obj[splittedKey[i]]
				} else {
					return ""
				}
			}
			return obj
		}
		return ""
	}

	getContentForKey = (key) => {
		let content = this.getContentForKeyLang(key, this.state.currentLanguage);
		if (content === "") {
			return this.getContentForKeyLang(key, this.state.defaultLanguage);
		}
		return content;
	}

	render() {
		return (
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Layout lang={this.state.currentLanguage} changeLanguage={this.changeLanguage} c={this.getContentForKey} />}>
						<Route path="/" element={<Home lang={this.state.currentLanguage} c={this.getContentForKey} />}>
						</Route>
						<Route path="/:lang/" element={<Home lang={this.state.currentLanguage} c={this.getContentForKey} />}>
						</Route>
						<Route path="/:lang/home" element={<Home lang={this.state.currentLanguage} c={this.getContentForKey} />}>
						</Route>
						<Route path="/:lang/rooms" element={<Rooms c={this.getContentForKey} />}>
						</Route>
						<Route path="/:lang/location" element={<Location c={this.getContentForKey} />}>
						</Route>
						<Route path="/:lang/service" element={<Service c={this.getContentForKey} />}>
						</Route>
						<Route path="/:lang/reservation" element={<Reservation lang={this.state.currentLanguage} c={this.getContentForKey}/>}>
						</Route>
						<Route path="/:lang/contact" element={<Contact c={this.getContentForKey} />}>
						</Route>
						<Route path="/privacypolicy" element={<Privacypolicy c={this.getContentForKey} />}>
						</Route>
						<Route path="/:lang/thx" element={<Thx c={this.getContentForKey} />}>
						</Route>
					</Route>
				</Routes>
			</BrowserRouter>
		);
	}
}

export default App;
