import React from "react";
import {Link} from "react-router-dom";
import "./Footer.css";

class Footer extends React.Component {

	render() {
		return (
			<footer className="p-3 text-center fw-light">
					<Link to={"/"+this.props.lang+"/contact"}>{this.props.c("footer.impressum")}</Link> | <Link to="/privacypolicy">{this.props.c("footer.datenschutz")}</Link> | Hotel Castell © {new Date().getFullYear()} {this.props.c("footer.deutschland")}
			</footer>
		)
	}
}

export default Footer;
